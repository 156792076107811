.form-container {
    background-color: #000;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    padding: 32px;
    margin: 32px auto;
    max-width: 600px;
  }
  
  .form-container h1 {
    font-size: 28px;
    margin-bottom: 32px;
  }
  
  .form-container p {
    font-size: 16px;
    margin-bottom: 24px;
  }
  
  .form-group {
    margin-bottom: 24px;
  }
  
  .form-group label {
    font-size: 16px;
    margin-bottom: 8px;
    display: block;
  }
  
  .form-group input[type="text"],
  .form-group input[type="email"],
  .form-group input[type="password"] {
    font-size: 16px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
  }
  
  .form-group button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    padding: 12px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s ease;
  }
  
  .form-group button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .form-group input:focus,
  .form-group select:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  