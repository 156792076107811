.card-container {
    flex-basis: calc(50% - 10px);
    margin-right: 20px;
  }

  .card-equal-height {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media (min-width: 576px) {
    .card-group {
      display: flex;
      flex-wrap: wrap;
    }
    .card {
        margin-right: 15px;
        margin-bottom: 15px;
        flex: 1 1 calc(50% - 15px);
      }
    }

  @media (min-width: 768px) {
    /* En pantallas grandes */
    .card {
      /* Las cards ocupan la mitad del ancho del contenedor */
      width: 100%;
      float: left;
      margin-bottom: 30px;
    }
  
    /* La primera card no tiene margen izquierdo */
    .card:first-child {
      margin-left: 0;
    }
  
    /* La última card no tiene margen derecho */
    .card:last-child {
      margin-right: 0;
    }
  }

  /* Estilos para el menú */
  .menu_left {
    width: 240px; /* Ancho del menú */
    height: 100vh; /* Altura del menú */
    position: fixed; /* Fijar el menú en la página */
    top: 86px;
    left: 0;
    padding: auto;
    background-color: #f2f2f2; /* Color de fondo del menú */
  }
  
  .menu_righ {
    width: 240px; /* Ancho del menú */
    height: 100vh; /* Altura del menú */
    position: fixed; /* Fijar el menú en la página */
    top: 86px;
    right: 0%;
    padding: auto;
    background-color: #f2f2f2; /* Color de fondo del menú */
  }

  /* Estilos para el contenido principal list bedrooms */
  .content_bedrooms {
    margin-left: 270px; /* Espacio entre el menú y el contenido principal */
    padding: 20px;
  }

  /* Estilos para el contenido principal list bedrooms */
  .content_bed {
    margin-right: 270px; /* Espacio entre el menú y el contenido principal */
    padding: 20px;
  }

  /* .card-block {
    font-size: 1em;
    position: relative;
    margin: 0;
    padding: 1em;
    border: none;
    border-top: 1px solid rgba(34, 36, 38, .1);
    box-shadow: none;    
} */

  .img_bedrooms {
    width: 50%;
    display: block; /* para que la imagen sea un elemento de bloque */
    margin: auto; /* para centrar la imagen horizontalmente */
   background-color: #ddd; /*  para darle un color de fondo a la imagen */
  }

  
  .nav-link {
    color: #343a40;
    font-weight: 500;
    padding: 10px 15px;
  }
  
  .nav-link:hover {
    background-color: #e2e3e5;
  }

  .form_right{
    margin: 6%;
  }

  .form_fecha{
    margin-top: 10px;
  }

  .date-range-picker {
    width: 200;
    margin: 6%;
    display: block;
    margin-bottom: 5;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-top: 10;
    margin-left: 10;
    z-index: 100;
  };

  .modal_piker_style{
    margin: auto;
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(10px);
    z-index: 100;
  }
